//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Alert',
  props: {
    dataAlert: {
      type: Object,
      default: null
    }
  }
}
