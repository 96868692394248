var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!!_vm.dataAlert)?_c('div',{staticClass:"rounded py-[6px] px-[8px] text-[14px] font-medium",class:_vm.dataAlert && _vm.dataAlert.type === 'error'
      ? 'bg-red-100 text-red-900'
      : 'bg-green-50 text-green-600'},[(_vm.dataAlert && _vm.dataAlert.title)?_c('div',{staticClass:"title"},[_c('strong',[_vm._v("\n      "+_vm._s((_vm.dataAlert && _vm.dataAlert.title) || '')+"\n    ")])]):_vm._e(),_vm._v(" "),_c('div',{},[(
        _vm.dataAlert &&
        Array.isArray(_vm.dataAlert.message) &&
        _vm.dataAlert.message.length > 1
      )?_c('div',_vm._l((_vm.dataAlert.message),function(item,i){return _c('div',{key:("toast-item-" + i)},[_vm._v("\n        "+_vm._s(item.detail)+"\n      ")])}),0):_c('span',[_vm._v("\n      "+_vm._s((_vm.dataAlert && _vm.dataAlert.message) ||
        "Tidak dapat memproses, silakan untuk coba lagi nanti.\n          Hubungi admin atau customer service untuk detail lebih lanjut.")+"\n    ")])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }